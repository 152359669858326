// deno-lint-ignore no-namespace
namespace DSPACEColor {
    export const dspace = '#ED2A7C' as const;
    export type dspace = typeof dspace;

    export const dspace1 = '#7D72EE' as const;
    export type dspace1 = typeof dspace1;

    export const dspace2 = dspace;
    export type dspace2 = typeof dspace2;

    export const dspace3 = '#CB1F89' as const;
    export type dspace3 = typeof dspace3;

    export const dspace4 = '#41565B' as const;
    export type dspace4 = typeof dspace4;

    export const dspace5 = '#1B4651' as const;
    export type dspace5 = typeof dspace5;

    export const ghostwhite = '#F8F9FA' as const;
    export type ghostwhite = typeof ghostwhite;
}

export default DSPACEColor;
